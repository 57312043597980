import {
  SectionType,
  PermissionRoleType,
  SectionDisplayMode,
} from '@enums'

import store from '@state/store'

import Condition from '@state/models/condition'
import DocumentBlueprint from '@state/models/document-blueprint'
import PermissionRole from '@state/models/permission-role'
import Section from '@state/models/section'
import {
  SelectGroup,
  SelectGroupOption,
} from '@state/models/select-group'
import {
  SectionSelectGroupOptionResult,
} from '@state/models/section-select-group-option-result'

import flattenByAttr from '@utils/flatten-by-attr'


export const state = {
  documentBlueprintId: null,
  documentBlueprintVersion: null,
  currentBlueprintVersion: null,

  sectionTrees: {},
  sections: {},
  conditions: {},
  selectGroups: {},
  selectGroupOptions: {},
  sectionSelectGroupOptionResults: {},

  selectedSelectGroupId: null,

  showMetaDataModal: false,
  translationMode: false,
  initialLoad: true,
  showSelectGroupModal: false,

  blueprintBelongsToBlueprintPermissionRoleId: null,

  sectionHidden: {},

  deletedResults: {},

  someSectionHasColumnDisplayMode: false, // cached value for performance. Not critical if it's not up to date

}

export const mutations = {
  RESET_DATA(state) {
    [
      'sections',
      'selectGroups',
      'selectGroupOptions',
      'sectionSelectGroupOptionResults',
    ].forEach((attr) => {
      Object.keys(state[attr]).forEach((blueprintVersionVersion) => {
        state[attr][blueprintVersionVersion] = []
      })
    })

    /* state.sections = {}
    state.selectGroups = {}
    state.selectGroupOptions = {}
    state.sectionSelectGroupOptionResults = {} */

    state.selectedSelectGroupId = null
    state.showMetaDataModal = false
    state.translationMode = false
    state.initialLoad = true

    state.deletedResults = {}

  },
  SET_SECTION_TREE_FOR_BLUEPRINT_VERSION(state, { sectionTree, blueprintVersion, topLevelSectionType }) {
    if (!state.sectionTrees[blueprintVersion]) {
      state.sectionTrees[blueprintVersion] = {}
    }
    state.sectionTrees[blueprintVersion][topLevelSectionType] = sectionTree

  },
  SET_DOCUMENT_BLUEPRINT_ID(state, { documentBlueprintId }) {
    state.documentBlueprintId = documentBlueprintId
  },
  SET_DOCUMENT_BLUEPRINT_VERSION(state, { documentBlueprintVersion }) {
    state['documentBlueprintVersion'] = documentBlueprintVersion
    state.sectionTrees[documentBlueprintVersion.version] = {}
  },
  SET_CURRENT_BLUEPRINT_VERSION(state, { currentBlueprintVersion }) {
    state.currentBlueprintVersion = currentBlueprintVersion
    state.sectionTrees[currentBlueprintVersion.version] = {}
  },
  SET_SECTIONS_FOR_BLUEPRINT_VERSION(state, { sections, blueprintVersion }) {
    state.someSectionHasColumnDisplayMode = sections.some(
      s => s.display_mode === SectionDisplayMode.COLUMNS
    )
    state.sections[blueprintVersion] = sections
  },
  SET_CONDITIONS_FOR_BLUEPRINT_VERSION(state, { conditions, blueprintVersion }) {
    state.conditions[blueprintVersion] = conditions
  },
  SET_SELECT_GROUPS_FOR_BLUEPRINT_VERSION(state, { selectGroups, blueprintVersion }) {
    state.selectGroups[blueprintVersion] = selectGroups
  },
  SET_SELECT_GROUP_OPTIONS_FOR_BLUEPRINT_VERSION(state, { selectGroupOptions, blueprintVersion }) {
    state.selectGroupOptions[blueprintVersion] = selectGroupOptions
  },
  SET_SECTION_SELECT_GROUP_OPTION_RESULTS_FOR_BLUEPRINT_VERSION(
    state,
    { sectionSelectGroupOptionResults, blueprintVersion },
  ) {
    state.sectionSelectGroupOptionResults[blueprintVersion] = sectionSelectGroupOptionResults
  },
  ADD_SECTION_FOR_BLUEPRINT_VERSION(state, { section, blueprintVersion }) {
    const existingSections = state.sections[blueprintVersion]
    let newSections = null

    const afterSection = existingSections.find(
      s => !!s.before_section_viid && s.before_section_viid === section.before_section_viid
    )
    if (afterSection) {
      afterSection.before_section_viid = section.viid
      // existingItem.sections.splice(existingItem.sections.indexOf(afterSection), 1, afterSection)
      /* newSections = existingSections.filter(s => s.viid !== afterSection.viid)
      newSections.push(afterSection) */
      const indexOfItem = existingSections.indexOf(afterSection)
      existingSections[indexOfItem] = afterSection

    }

    existingSections.push(section)

  },
  ADD_CONDITION_FOR_BLUEPRINT_VERSION(state, { condition, blueprintVersion }) {
    const existingConditions = state.conditions[blueprintVersion]

    existingConditions.push(condition)

  },
  ADD_SELECT_GROUP_FOR_BLUEPRINT_VERSION(state, { selectGroup, blueprintVersion }) {
    const existingSelectGroups = state.selectGroups[blueprintVersion]

    existingSelectGroups.push(selectGroup)

  },
  ADD_SELECT_GROUP_OPTION_FOR_BLUEPRINT_VERSION(state, { selectGroupOption, blueprintVersion }) {
    const existingSelectGroupOptions = state.selectGroupOptions[blueprintVersion]

    existingSelectGroupOptions.push(selectGroupOption)

  },
  ADD_SECTION_SELECT_GROUP_OPTION_RESULT_FOR_BLUEPRINT_VERSION(state, { sectionSelectGroupOptionResult, blueprintVersion }) {
    let existingSsgors = state.sectionSelectGroupOptionResults[blueprintVersion]

    if (!existingSsgors) {
      state.sectionSelectGroupOptionResults[blueprintVersion] = []
      existingSsgors = state.sectionSelectGroupOptionResults[blueprintVersion]
    }

    existingSsgors.push(sectionSelectGroupOptionResult)

  },
  SET_SELECTED_SELECT_GROUP_ID(state, selectedSelectGroupId) {
    state.selectedSelectGroupId = selectedSelectGroupId
  },
  SET_SHOW_SELECT_GROUP_MODAL(state, showSelectGroupModal) {
    state.showSelectGroupModal = showSelectGroupModal
  },
  SET_SHOW_METADATA_MODAL(state, showMetaDataModal) {
    state.showMetaDataModal = showMetaDataModal
  },
  SET_TRANSLATION_MODE(state, translationMode) {
    state.translationMode = translationMode
  },
  SET_INITIAL_LOAD(state, initialLoad) {
    state.initialLoad = initialLoad
  },
  UPDATE_SECTION(state, { section, blueprintVersion }) {
    const existingSections = state.sections[blueprintVersion]

    const existingSection = existingSections.find(s => s.viid === section.viid)

    if (existingSection) {
      // if (!isEqual(existingSection, section)) {
        const index = existingSections.indexOf(existingSection)
        existingSections[index] = section
      // }

    } else {
      console.warn('couldnt find section in blueprintVersion', section, blueprintVersion)
    }

  },
  UPDATE_CONDITION(state, { condition, blueprintVersion }) {
    const existingConditions = state.conditions[blueprintVersion]

    const existingCondition = existingConditions.find(c => c.viid === condition.viid)
    if (existingCondition) {
      // if (!isEqual(existingCondition, condition)) {
        existingConditions[existingConditions.indexOf(existingCondition)] = condition
      // }
    } else {
      console.warn('couldnt find condition in blueprintVersion', condition, blueprintVersion)
    }

  },
  UPDATE_SELECT_GROUP(state, { selectGroup, blueprintVersion }) {
    const existingSelectGroups = state.selectGroups[blueprintVersion]

    const existingSelectGroup = existingSelectGroups.find(sg => sg.viid === selectGroup.viid)
    if (existingSelectGroup) {
      // if (!isEqual(existingSelectGroup, selectGroup)) {
        existingSelectGroups[existingSelectGroups.indexOf(existingSelectGroup)] = selectGroup
      // }
    } else {
      console.warn('couldnt find selectGroup in blueprintVersion', selectGroup, blueprintVersion)
    }

  },
  UPDATE_SELECT_GROUP_OPTION(state, { selectGroupOption, blueprintVersion }) {
    const existingSelectGroupOptions = state.selectGroupOptions[blueprintVersion]

    const existingSelectGroupOption = existingSelectGroupOptions.find(sgo => sgo.viid === selectGroupOption.viid)
    if (existingSelectGroupOption) {
      // if (!isEqual(existingSelectGroupOption, selectGroupOption)) {
        existingSelectGroupOptions[existingSelectGroupOptions.indexOf(existingSelectGroupOption)] = selectGroupOption
      // }
    } else {
      console.warn('couldnt find selectGroupOption in blueprintVersion', selectGroupOption, blueprintVersion)
    }

  },
  UPDATE_SECTION_SELECT_GROUP_OPTION_RESULT(state, { sectionSelectGroupOptionResult, blueprintVersion }) {
    const existingSsgors = state.sectionSelectGroupOptionResults[blueprintVersion]

    const existingSsgor = existingSsgors.find(ssgor => ssgor.viid === sectionSelectGroupOptionResult.viid)
    if (existingSsgor) {
      // if (!isEqual(existingSsgor, sectionSelectGroupOptionResult)) {
        existingSsgors[existingSsgors.indexOf(existingSsgor)] = sectionSelectGroupOptionResult
      // }
    } else {
      console.warn(
        'couldnt find sectionSelectGroupOptionResult in blueprintVersion',
        sectionSelectGroupOptionResult,
        blueprintVersion,
      )
    }

  },
  SET_BLUEPRINT_BELONGS_TO_BLUEPRINT_PERMISSION_ROLE_ID(state, blueprintBelongsToBlueprintPermissionRoleId) {
    state.blueprintBelongsToBlueprintPermissionRoleId = blueprintBelongsToBlueprintPermissionRoleId
  },
  DELETE_SECTION(state, { section, blueprintVersion }) {
    const existingSection = state.sections[blueprintVersion].find(s => s.viid === section.viid)
    state.sections[blueprintVersion].splice(state.sections[blueprintVersion].indexOf(existingSection), 1)
  },
  DELETE_CONDITION(state, { condition, blueprintVersion }) {
    const existingCondition = state.conditions[blueprintVersion].find(c => c.viid === condition.viid)
    state.conditions[blueprintVersion].splice(state.conditions[blueprintVersion].indexOf(existingCondition), 1)
  },
  DELETE_SELECT_GROUP(state, { selectGroup, blueprintVersion }) {
    const existingSelectGroup = state.selectGroups[blueprintVersion].find(sg => sg.viid === selectGroup.viid)
    state.selectGroups[blueprintVersion].splice(state.selectGroups[blueprintVersion].indexOf(existingSelectGroup), 1)
  },
  DELETE_SELECT_GROUP_OPTION(state, { selectGroupOption, blueprintVersion }) {
    const existingSelectGroupOption = state.selectGroupOptions[blueprintVersion].find(sgo => sgo.viid === selectGroupOption.viid)
    state.selectGroupOptions[blueprintVersion].splice(state.selectGroupOptions[blueprintVersion].indexOf(existingSelectGroupOption), 1)
  },
  DELETE_SECTION_SELECT_GROUP_OPTION_RESULT(state, { sectionSelectGroupOptionResult, blueprintVersion }) {
    const existingSectionSelectGroupOptionResult = state.sectionSelectGroupOptionResults[blueprintVersion].find(ssgor => ssgor.viid === sectionSelectGroupOptionResult.viid)
    state.sectionSelectGroupOptionResults[blueprintVersion].splice(state.sectionSelectGroupOptionResults[blueprintVersion].indexOf(existingSectionSelectGroupOptionResult), 1)
  },
  SET_SECTION_HIDDEN(state, { sectionHidden }) {
    state.sectionHidden = sectionHidden
  },
  SET_DELETED_RESULTS(state, deletedResults) {
    state.deletedResults = deletedResults
  },
}

export const getters = {
  documentBlueprint(state) {
    return DocumentBlueprint.find(state.documentBlueprintId)
  },
  documentBlueprintVersion(state) {
    return state.documentBlueprintVersion
  },
  currentBlueprintVersion(state) {
    return state.currentBlueprintVersion
  },
  sectionTreesByBlueprintVersion(state) {
    return (blueprintVersion) => {
      const sectionTrees = state.sectionTrees[blueprintVersion]
      return sectionTrees
    }
  },
  sectionTreeByBlueprintVersion(state) {
    return (blueprintVersion, topLevelSectionType) => {
      topLevelSectionType = topLevelSectionType || SectionType.SUBSECTION
      const sectionTree = state.sectionTrees[blueprintVersion][topLevelSectionType]
      return sectionTree
    }
  },
  sections: state => state.sections,
  sectionsBlueprintVersion(state) {
    return (blueprintVersion) => {
      const sections = state.sections[blueprintVersion]
      return sections
    }
  },
  conditions: state => state.conditions,
  conditionsBlueprintVersion(state) {
    return (blueprintVersion) => {
      const conditions = state.conditions[blueprintVersion]
      return conditions
    }
  },
  selectGroupsBlueprintVersion(state) {
    return (blueprintVersion) => {
      const selectGroups = state.selectGroups[blueprintVersion]
      return selectGroups
    }
  },
  selectGroupOptionsBlueprintVersion(state) {
    return (blueprintVersion) => {
      const selectGroupOptions = state.selectGroupOptions[blueprintVersion]
      return selectGroupOptions
    }
  },
  sectionSelectGroupOptionResultsBlueprintVersion(state) {
    return (blueprintVersion) => {
      const sectionSelectGroupOptionResults = state.sectionSelectGroupOptionResults[blueprintVersion]
      return sectionSelectGroupOptionResults || []
    }
  },
  sortedFlattenedSectionItems(state, getters) {
    return (blueprintVersion, topLevelSectionType) => {
      const sectionTree = getters.sectionTreeByBlueprintVersion(blueprintVersion, topLevelSectionType)
      if (!sectionTree) {
        return []
      }

      const flatSectionItems = flattenByAttr(sectionTree, 'children')

      return flatSectionItems
    }
  },
  conditionSectionSelectItems(state, getters) {
    return (blueprintVersion) => {
      const items = []

      const flatSectionItems = getters.sortedFlattenedSectionItems(blueprintVersion, SectionType.SUBSECTION)

      flatSectionItems.forEach((sectionItem) => {
        const section = sectionItem.section
        if (
          section.section_type === SectionType.QUESTION ||
          section.section_type === SectionType.CONDITION_GROUP
        ) {
          items.push({
            value: section.viid,
            text:
              sectionItem.actualPosition +
              ' ' +
              section.text_translations[getters.documentBlueprint.current_locale],
          })
        }
      })

      return items

    }
  },
  topLevelSectionItemsByBlueprintVersion(state, getters) {
    return (blueprintVersion, topLevelSectionType) => {
      topLevelSectionType = topLevelSectionType || SectionType.SUBSECTION

      const sectionTree = getters.sectionTreeByBlueprintVersion(blueprintVersion, topLevelSectionType)
      if (!sectionTree) {
        console.warn('no sectionTree for blueprintVersion', blueprintVersion, 'and topLevelSectionType', topLevelSectionType)
        return []
      }

      return sectionTree
        .map((sectionItem) => {
          return {
            ...sectionItem,
            id: sectionItem.section.id,
          }
        })
    }
  },
  selectedSelectGroupId(state) {
    return state.selectedSelectGroupId
  },
  selectGroupItems(state) {
    return (blueprintVersion, currentLocale) => {
      const selectGroups = state.selectGroups[blueprintVersion]
      return selectGroups
        .map((selectGroup) => {
          return {
            selectGroup,
            selectGroupId: selectGroup.id,
            text: selectGroup.name_translations[currentLocale],
          }
        })
    }
  },
  initialLoad: state => state.initialLoad,
  showSelectGroupModal: state => state.showSelectGroupModal,
  showMetaDataModal: state => state.showMetaDataModal,
  translationMode: state => state.translationMode,
  blueprintBelongsToBlueprintPermissionRoleId: state => state.blueprintBelongsToBlueprintPermissionRoleId,
  sectionHidden: state => state.sectionHidden,
  topLevelSectionType() {
    const currentRoute = store.getters['navbar/currentRoute']
    return currentRoute.name.indexOf('results') !== -1 ?
        SectionType.RESULT :
        currentRoute.name.indexOf('conditionGroups') !== -1 ?
        SectionType.CONDITION_GROUP :
        SectionType.SUBSECTION
  },
  deletedResults: state => state.deletedResults,
  someSectionHasColumnDisplayMode: state => state.someSectionHasColumnDisplayMode,
}

export const actions = {
  init({ state, dispatch, getters, commit }) {
    const storedSectionHidden = JSON.parse(window.localStorage.getItem('planit_docs:sectionHidden')) || {}
    commit('SET_SECTION_HIDDEN', { sectionHidden: storedSectionHidden })
  },

  setSections({ commit }, { sections, blueprintVersion }) {
    commit('SET_SECTIONS_FOR_BLUEPRINT_VERSION', { sections, blueprintVersion })
  },

  setSelectGroups({ commit }, { selectGroups, blueprintVersion }) {
    commit('SET_SELECT_GROUPS_FOR_BLUEPRINT_VERSION', { selectGroups, blueprintVersion })
  },

  addSection({ commit }, { section, blueprintVersion }) {
    commit('ADD_SECTION_FOR_BLUEPRINT_VERSION', { section, blueprintVersion })
  },

  addCondition({ commit }, { condition, blueprintVersion }) {
    commit('ADD_CONDITION_FOR_BLUEPRINT_VERSION', { condition, blueprintVersion })
  },

  addSelectGroup({ commit }, { selectGroup, blueprintVersion }) {
    commit('ADD_SELECT_GROUP_FOR_BLUEPRINT_VERSION', { selectGroup, blueprintVersion })
  },

  addSelectGroupOption({ commit }, { selectGroupOption, blueprintVersion }) {
    commit('ADD_SELECT_GROUP_OPTION_FOR_BLUEPRINT_VERSION', { selectGroupOption, blueprintVersion })
  },

  addSectionSelectGroupOptionResult({ commit }, { sectionSelectGroupOptionResult, blueprintVersion }) {
    commit('ADD_SECTION_SELECT_GROUP_OPTION_RESULT_FOR_BLUEPRINT_VERSION', { sectionSelectGroupOptionResult, blueprintVersion })
  },

  buildSectionTree({ state, commit, getters }, { blueprintVersion, sectionTypes, page = null } = {}) {
    const sections = (state.sections[blueprintVersion] || [])
    .filter(s => sectionTypes.indexOf(s.section_type) !== -1)
    .filter(s => !s.marked_for_deletion)

    let topLevelItems

    const getSectionByBeforeSection = (currentBeforeSectionViid, parentSectionViid) => {
      const beforeSection = sections.find((section) => {
        return section.parent_section_viid === parentSectionViid &&
          section.before_section_viid === currentBeforeSectionViid
      })

      return beforeSection

    }

    const buildSectionTreeChildren = (parentItem, level) => {
      const parentSection = parentItem.section

      const children = []
      let currentBeforeSectionViid = null
      let currentSection = getSectionByBeforeSection(currentBeforeSectionViid, parentSection.viid)
      while (currentSection) {
        children.push(currentSection)
        currentBeforeSectionViid = currentSection.viid
        currentSection = getSectionByBeforeSection(currentBeforeSectionViid, parentSection.viid)
      }

      let p = 1
      const mappedChildren = children.map((childSection) => {
        const actualPosition = parentItem.actualPosition + '.' + p
        const fullPosition = (topLevelItems.length > 1 || level > 1)
          ? (parentItem.fullPosition ? (parentItem.fullPosition + '.' + p) : p.toString())
          : p.toString()

        const childItem = {
          section: childSection,
          id: childSection.id,
          parentItem,
          position: p,
          fullPosition,
          actualPosition,
          level,
        }

        p++

        childItem.children = buildSectionTreeChildren(childItem, level + 1)
        return childItem

      })

      // parent is level 1. their positions should include trailing dot in fullPosition
      // if (parentItem && children.length !== 0 && level == (topLevelItems.length === 1 ? 3 : 2)) {
      if (
        parentItem &&
        level == (topLevelItems.length === 1 ? 3 : 2)
      ) {
        // parentItem.actualPosition
        parentItem.fullPosition += '.'
      }

      return mappedChildren

    }

    let p = 0
    let level = 1
    const sortedTopLevelSections = []

    let currentTopLevelBeforeSectionViid = null
    let currentTopLevelSection = getSectionByBeforeSection(currentTopLevelBeforeSectionViid, null)
    while (currentTopLevelSection) {
      sortedTopLevelSections.push(currentTopLevelSection)
      currentTopLevelBeforeSectionViid = currentTopLevelSection.viid
      currentTopLevelSection = getSectionByBeforeSection(currentTopLevelBeforeSectionViid, null)
    }

    topLevelItems = sortedTopLevelSections
    .map((section) => {
      p++

      const tlsItem = {
        section,
        id: section.id,
        position: p,
        fullPosition: sortedTopLevelSections.length > 1 ? p.toString() : '',
        actualPosition: p.toString(),
        page: p,
        level,
      }

      return tlsItem
    })

    topLevelItems.forEach((parentItem) => {
      parentItem.children = buildSectionTreeChildren(parentItem, level + 1)
    })

    const sectionTree = topLevelItems

    let topLevelSectionType
    if (sectionTypes.includes(SectionType.SUBSECTION)) {
      topLevelSectionType =  SectionType.SUBSECTION

    } else if (sectionTypes.includes(SectionType.RESULT)) {
      topLevelSectionType =  SectionType.RESULT

    } else if (sectionTypes.includes(SectionType.CONDITION_GROUP)) {
      topLevelSectionType =  SectionType.CONDITION_GROUP

    } else {
      console.warn('No topLevelSectionType could be determined')
      topLevelSectionType =  SectionType.SUBSECTION
    }

    commit(
      'SET_SECTION_TREE_FOR_BLUEPRINT_VERSION',
      {
        sectionTree,
        blueprintVersion,
        topLevelSectionType,
        page,
      },
    )

  },

  async resetData({ commit }) {
    const deletePromises = [
      Section.deleteAll(),
      Condition.deleteAll(),
      SelectGroup.deleteAll(),
      SelectGroupOption.deleteAll(),
      SectionSelectGroupOptionResult.deleteAll(),
    ]
    await Promise.all(deletePromises)

    commit('RESET_DATA')

  },

  async refreshBlueprint({ state, commit, getters, dispatch }) {
    const result = await DocumentBlueprint.$find(state.documentBlueprintId)

    if (!result?.response?.data?.permission_role_model_associations) {
      console.warn('No permission_role_model_associations found for document blueprint')
    }

    await result.entities['document-blueprints'][0].fillFromLocalStorage()

    let blueprintPermissionRoleId = null
    const licensePermissionRolePromises = []
    if (result?.response?.data?.permission_role_model_associations) {
      result.response.data.permission_role_model_associations.forEach((prma) => {
        licensePermissionRolePromises.push(
          PermissionRole.$find(prma.permission_role_id).then((prResult) => {
            if (prResult.response.data.permission_role_type === PermissionRoleType.DOCUMENT_BLUEPRINTS) {
              blueprintPermissionRoleId = prResult.response.data.id
            }

            if (prResult.response.data.license_permission_role_id) {
              licensePermissionRolePromises.push(
                PermissionRole.$find(prResult.response.data.license_permission_role_id).then((lprResult) => {
                  return PermissionRole.$all({
                    license_permission_role_id: prResult.response.data.license_permission_role_id,
                    permission_role_type: PermissionRoleType.MENU_FOLDERS,
                  })
                })
              )
            }
          })
        )
      })
    }

    await Promise.all(licensePermissionRolePromises)

    if (blueprintPermissionRoleId) {
      commit('SET_BLUEPRINT_BELONGS_TO_BLUEPRINT_PERMISSION_ROLE_ID', blueprintPermissionRoleId)
      await store.dispatch('currentLicensePermissionRole/setBlueprintPermissionRoleId', blueprintPermissionRoleId)

    }

  },
  async refreshSections({ state, commit, getters, dispatch }, ) {
    const documentBlueprintVersion = getters.documentBlueprintVersion
    Section.deleteAll()
    const results = await documentBlueprintVersion.refreshSubResource(Section)
    commit('SET_SECTIONS_FOR_BLUEPRINT_VERSION', {
      blueprintVersion: documentBlueprintVersion.version,
      sections: results.response.data,
    })
  },
  async refreshSectionSelectGroupOptionResults({ state, commit, getters, dispatch }, ) {
    const documentBlueprintVersion = getters.documentBlueprintVersion
    const results = await documentBlueprintVersion.refreshSubResource(SectionSelectGroupOptionResult)

    commit('SET_SECTION_SELECT_GROUP_OPTION_RESULTS_FOR_BLUEPRINT_VERSION', {
      blueprintVersion: documentBlueprintVersion.version,
      sectionSelectGroupOptionResults: results.entities['section-select-group-option-results'],
    })
  },
  async refreshBlueprintVersion(
    { state, commit, getters, dispatch },
    { documentBlueprintVersion, models } = {},
  ) {
    models = models || [
      'section',
      'condition',
      'selectGroup',
      'selectGroupOption',
      'sectionSelectGroupOptionResult',
    ]

    await dispatch('resetData')

    const promises = []
    const objs = []
    const params = {}
    const currentRoute = store.getters['navbar/currentRoute']
    const isDocumentResultsPage = currentRoute.name === 'documents.detail.results' ||
    currentRoute.name === 'admin.documentBlueprint.templates.detail.results'
    if (!isDocumentResultsPage) {
      params.minimal_section_schema = true
    }
    if (models.includes('section')) { objs.push({ model: Section, attr: 'sectionIds', params: params }) }
    if (models.includes('condition')) { objs.push({ model: Condition, attr: 'conditionIds' }) }
    if (models.includes('selectGroup')) { objs.push({ model: SelectGroup, attr: 'selectGroupIds' }) }
    if (models.includes('selectGroupOption')) { objs.push({ model: SelectGroupOption, attr: 'selectGroupOptionIds' }) }
    if (models.includes('sectionSelectGroupOptionResult') && isDocumentResultsPage ) { objs.push({ model: SectionSelectGroupOptionResult, attr: 'sectionSelectGroupOptionResultIds' }) }

    const resultIds = {}
    const results = {}

    objs.forEach((obj) => {
      promises.push(
        documentBlueprintVersion.refreshSubResource(obj.model, { params: obj.params || {} }).then((result) => {
          resultIds[obj.attr] = result.response.data.map(d => d.id)
          results[obj.attr] = result
        })
      )
    })

    await Promise.all(promises)

    if (models.includes('section')) {
      commit('SET_SECTIONS_FOR_BLUEPRINT_VERSION', {
        blueprintVersion: documentBlueprintVersion.version,
        sections: resultIds.sectionIds.map(sId => results['sectionIds'].entities['sections'].find(s => s.id === sId)),
      })
    }


    if (models.includes('condition')) {
      commit('SET_CONDITIONS_FOR_BLUEPRINT_VERSION', {
        blueprintVersion: documentBlueprintVersion.version,
        conditions: resultIds.conditionIds.map(cId => results['conditionIds'].entities['conditions'].find(c => c.id === cId)),
      })
    }

    if (models.includes('selectGroup')) {
      commit('SET_SELECT_GROUPS_FOR_BLUEPRINT_VERSION', {
        blueprintVersion: documentBlueprintVersion.version,
        selectGroups: resultIds.selectGroupIds.map(sgId => results['selectGroupIds'].entities['select-groups'].find(sg => sg.id === sgId)),
      })
    }

    if (models.includes('selectGroupOption')) {
      commit('SET_SELECT_GROUP_OPTIONS_FOR_BLUEPRINT_VERSION', {
        blueprintVersion: documentBlueprintVersion.version,
        selectGroupOptions: resultIds.selectGroupOptionIds.map(
          sgoId => results['selectGroupOptionIds'].entities['select-group-options'].find(sgo => sgo.id === sgoId)
        ),
      })
    }

    if (models.includes('sectionSelectGroupOptionResult') && isDocumentResultsPage) {
      commit('SET_SECTION_SELECT_GROUP_OPTION_RESULTS_FOR_BLUEPRINT_VERSION', {
        blueprintVersion: documentBlueprintVersion.version,
        sectionSelectGroupOptionResults: resultIds.sectionSelectGroupOptionResultIds.map(
          ssgorId => results['sectionSelectGroupOptionResultIds'].entities['section-select-group-option-results'].find(ssgor => ssgor.id === ssgorId)
        ),
      })
    }

    await dispatch('refreshDeletedResults', { documentBlueprintVersion })

  },
  setSelectedSelectGroupId({ commit, getters }, { selectGroupId = null, blueprintVersion } = {}) {
    let selectGroupItems = getters.selectGroupItems(blueprintVersion)

    if (!selectGroupId && selectGroupItems.length > 0) {
      selectGroupId = selectGroupItems[0].selectGroup.id

    }

    commit('SET_SELECTED_SELECT_GROUP_ID', selectGroupId)
    commit('SET_SHOW_SELECT_GROUP_MODAL', true)

  },

  updateSectionInAllVersions({ commit, state }, { section }) {
    Object.keys(state.sections).forEach((blueprintVersion) => {
      commit('UPDATE_SECTION', { section, blueprintVersion })
    })
  },

  updateConditionInAllVersions({ commit, state }, { condition }) {
    Object.keys(state.conditions).forEach((blueprintVersion) => {
      commit('UPDATE_CONDITION', { condition, blueprintVersion })
    })
  },

  startTranslationMode({ commit, getters, dispatch }, { documentBlueprintId, documentBlueprintVersion }) {
    const newSectionHidden = {}

    getters.sectionsBlueprintVersion(
      documentBlueprintVersion.version
    ).forEach((section) => {
      newSectionHidden[section.viid] = false
    })

    dispatch('setSectionHidden', {
      sectionHidden: newSectionHidden,
    })

    commit('SET_TRANSLATION_MODE', true)

  },

  toggleSectionHidden({ commit, getters, dispatch }, { sectionViid, documentBlueprintVersion, value }) {
    const sectionTree = getters.sectionTreeByBlueprintVersion(documentBlueprintVersion.version, getters.topLevelSectionType)

    const findInTree = (treeItem) => {
      if (treeItem.section?.viid === sectionViid) {
        return treeItem
      }
      let ret = null
      if (treeItem.children) {
        treeItem.children.some((child) => {
          ret = findInTree(child)
          if (ret) {
            return true
          }
          return false
        })
        return ret
      }
    }

    const partialTree = findInTree({ children: sectionTree })

    const flatSectionItems = flattenByAttr([partialTree], 'children')
      .filter(si => si.section.viid !== sectionViid)

    const sectionHiddenCopy = { ...getters.sectionHidden }
    flatSectionItems.forEach((si) => {
      sectionHiddenCopy[si.section.viid] = value
    })

    dispatch('setSectionHidden', { sectionHidden: sectionHiddenCopy })

  },

  setSectionHidden({ commit }, { sectionHidden }) {
    commit('SET_SECTION_HIDDEN', { sectionHidden })
    window.localStorage.setItem('planit_docs:sectionHidden', JSON.stringify(sectionHidden))
  },

  async refreshDeletedResults({ commit, state, getters }, { documentBlueprintVersion }) {
    const deletedPromises = []
    const deletedResults = {}

    const delObjs = [
      { model: Section, attr: 'sections' },
      { model: Condition, attr: 'conditions' },
    ]
    delObjs.forEach((obj) => {
      deletedPromises.push(
        documentBlueprintVersion.refreshSubResource(obj.model, { params: { deleted: true }, save: false }).then((result) => {
          // deletedResultIds[obj.attr] = result.response.data.map(d => d.id)
          deletedResults[obj.attr] = result
        })
      )
    })

    await Promise.allSettled(deletedPromises)

    for (const [k, v] of Object.entries(deletedResults)) {
      deletedResults[k] = v.response.data
    }

    commit('SET_DELETED_RESULTS', deletedResults)
  },

}
