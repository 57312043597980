import axios from 'axios'

import { Model } from '@vuex-orm/core'

import i18nLegacy from '@plugins/vue-i18n-legacy'

import { createI18n } from 'vue-i18n'
import * as messages from '@i18n'

import store from '@state/store'

import {
  PermissionRoleType,
} from '@enums'

import PlanitModel from './planit-model'
import { DocumentVersion } from './document-version'
import DocumentBlueprint from './document-blueprint'
import {
  DocumentBlueprintVersion,
} from './document-blueprint-version'
import Company from './company'
import Answer from './answer'
import CompanyDocumentTemplateAllotment from './company-document-template-allotment'
import { SelectGroup, SelectGroupOption } from './select-group'
import Section from './section'
import File from './file'
import Folder from './folder'
import FolderLink from '@state/models/folder-link'
import User from './user'
import Invite from './invite'
// import { SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation } from './section-select-group-option-result'
import { SectionSelectGroupOptionResult } from './section-select-group-option-result'
import PermissionRole from '@state/models/permission-role'
import PermissionRoleModelAssociation from '@state/models/permission-role-model-association'
import {
  AnswerSectionSelectGroupOptionResultUserData,
} from '@state/models/answer-section-select-group-option-result-user-data'


export class DocumentDiffData extends Model {
  static fields() {
    return {
      id: this.uid(),

      new_ids: this.attr([]),
      new: this.hasManyBy(Section, 'new_ids'),

      updated_ids: this.attr([]),
      updated: this.hasManyBy(Section, 'updated_ids'),

      updated_new_ids: this.attr([]),
      updated_new: this.hasManyBy(Section, 'updated_new_ids'),

      deleted_ids: this.attr([]),
      deleted: this.hasManyBy(Section, 'deleted_ids'),

      answers_ids: this.attr([]),
      answers: this.hasManyBy(Answer, 'answers_ids'),

      target_answers_ids: this.attr([]),
      target_answers: this.hasManyBy(Answer, 'target_answers_ids'),

      select_groups_new_ids: this.attr([]),
      select_groups_new: this.hasManyBy(SelectGroup, 'select_groups_new_ids'),

      select_groups_updated_ids: this.attr([]),
      select_groups_updated: this.hasManyBy(SelectGroup, 'select_groups_updated_ids'),

      select_groups_updated_new_ids: this.attr([]),
      select_groups_updated_new: this.hasManyBy(SelectGroup, 'select_groups_updated_new_ids'),

      select_groups_deleted_ids: this.attr([]),
      select_groups_deleted: this.hasManyBy(SelectGroup, 'select_groups_deleted_ids'),

      select_group_options_new_ids: this.attr([]),
      select_group_options_new: this.hasManyBy(SelectGroupOption, 'select_group_options_new_ids'),

      select_group_options_updated_ids: this.attr([]),
      select_group_options_updated: this.hasManyBy(
        SelectGroupOption,
        'select_group_options_updated_ids'
      ),

      select_group_options_updated_new_ids: this.attr([]),
      select_group_options_updated_new: this.hasManyBy(
        SelectGroupOption,
        'select_group_options_updated_new_ids'
      ),

      select_group_options_deleted_ids: this.attr([]),
      select_group_options_deleted: this.hasManyBy(
        SelectGroupOption,
        'select_group_options_deleted_ids'
      ),

      /* ssgordbvas_new_ids: this.attr([]),
      ssgordbvas_new: this.hasManyBy(
        SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation,
        'ssgordbvas_new_ids'
      ),

      ssgordbvas_updated_old_ids: this.attr([]),
      ssgordbvas_updated_old: this.hasManyBy(
        SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation,
        'ssgordbvas_updated_old_ids'
      ),

      ssgordbvas_updated_new_ids: this.attr([]),
      ssgordbvas_updated_new: this.hasManyBy(
        SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation,
        'ssgordbvas_updated_new_ids'
      ),

      ssgordbvas_deleted_ids: this.attr([]),
      ssgordbvas_deleted: this.hasManyBy(
        SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation,
        'ssgordbvas_deleted_ids'
      ), */

      ssgors_new_ids: this.attr([]),
      ssgors_new: this.hasManyBy(
        SectionSelectGroupOptionResult,
        'ssgors_new_ids'
      ),

      ssgors_updated_old_ids: this.attr([]),
      ssgors_updated_old: this.hasManyBy(
        SectionSelectGroupOptionResult,
        'ssgors_updated_old_ids'
      ),

      ssgors_updated_new_ids: this.attr([]),
      ssgors_updated_new: this.hasManyBy(
        SectionSelectGroupOptionResult,
        'ssgors_updated_new_ids'
      ),

      ssgors_deleted_ids: this.attr([]),
      ssgors_deleted: this.hasManyBy(
        SectionSelectGroupOptionResult,
        'ssgors_deleted_ids'
      ),

      document_id: this.attr(),

      current_upgrade_document_blueprint_version_id: this.attr(),

      edit_document_version_id: this.attr(),
      edit_document_version: this.belongsTo(DocumentVersion, 'edit_document_version_id'),

      head_document_version_id: this.attr(),
      head_document_version: this.belongsTo(DocumentVersion, 'head_document_version_id'),

      minimum_license_type: this.attr(),

      deadline: this.attr(),
    }
  }
}

DocumentDiffData.entity = 'diff-data'

export class Document extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      name_translations: this.attr(),
      primary_folder_name: this.attr(),

      document_type: this.attr(),
      availability: this.attr(),

      allowed_company_ids: this.attr(), // helper that lists all allowed company ids for admins

      deadline: this.attr(),
      lock_mode: this.attr(),
      result_view_mode: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      primary_folder_id: this.attr(),
      primary_folder: this.belongsTo(Folder, 'primary_folder_id'),

      // document_id: this.attr(),
      document_versions: this.hasMany(DocumentVersion, 'document_id'),

      /* current_version_id: this.attr(),
      current_version: this.hasOne(DocumentVersion, 'document_id', 'current_version_id'), */

      head_version_id: this.attr(),
      head_version: this.belongsTo(DocumentVersion, 'head_version_id'),

      edit_document_version_id: this.attr(),
      edit_document_version: this.belongsTo(DocumentVersion, 'edit_document_version_id'),

      repository_document_version_id: this.attr(),
      repository_document_version: this.belongsTo(DocumentVersion, 'repository_document_version_id'),

      document_blueprint_id: this.attr(),
      document_blueprint: this.belongsTo(DocumentBlueprint, 'document_blueprint_id'),

      current_upgrade_document_blueprint_version_id: this.attr(),
      // current_upgrade_document_blueprint_version_id_reverse: this.attr(),
      current_upgrade_document_blueprint_version: this.belongsTo(
        DocumentBlueprintVersion,
        'current_upgrade_document_blueprint_version_id'
      ),

      company_document_template_allotments: this.hasMany(
        CompanyDocumentTemplateAllotment,
        'document_id'
      ),

      // diff_data: this.hasOne(DocumentDiffData, 'document_id'),
      current_progress: this.attr(0),
      upgrade_available: this.attr(),

      last_modified_by_id: this.attr(),
      last_modified_by: this.belongsTo(User, 'last_modified_by_id'),

      // created_by_id: this.attr(),  // mb for later use
      // created_by: this.belongsTo(User, 'created_by_id'),

      last_commit_at: this.attr(),

      uuid: this.attr(),

      attribute_ids: this.attr(),
      attributes: this.hasManyBy(Answer, 'attribute_ids'), // helper for repository documents

      // attrs for repo_documents
      repository_edit_document_version_id: this.attr(),
      repository_edit_document_version: this.belongsTo(DocumentVersion, 'repository_edit_document_version_id'),
      repository_current_document_version_id: this.attr(),
      repository_current_document_version: this.belongsTo(DocumentVersion, 'repository_current_document_version_id'),

      // local attrs
      current_locale: this.attr(),

      show_all_pages: this.attr(),
      show_empty_only: this.attr(),
      show_required_only: this.attr(),

      folder_links: this.hasMany(FolderLink, 'folder_id'),

      me_can_edit: this.attr(),
      me_can_assign: this.attr(),

      use_as_template: this.attr(),

      minimum_license_type: this.attr(),
      menu_structure_folder_name: this.attr(),
    }
  }

  static beforeCreate(model) {
    model.current_locale = model.current_locale || store.getters['auth/currentLocale']
  }

  static getSortAttrs() {
    return [
      (d) => {
        let folderLink
        const menuFoldersPermissionRole = store.getters['navbar/menuFoldersPermissionRole']
        const documentBlueprintId = d.document_blueprint?.id || d.document_blueprint_id

        if (!menuFoldersPermissionRole) {
          folderLink = FolderLink.allFast().find((fl) => {
            return (
              fl.document_blueprint_id === documentBlueprintId &&
              !PermissionRoleModelAssociation.allFast().find(
                (prma) =>
                  prma.folder_id === fl.folder_id &&
                  !!PermissionRole.allFast().find(
                    (pr) =>
                      pr.id === prma.permission_role_id &&
                      pr.permission_role_type === PermissionRoleType.MENU_FOLDERS
                  )
              )
            )
          })

        } else {
          folderLink = FolderLink.allFast().find((fl) => {
            return (
              fl.document_blueprint_id === documentBlueprintId &&
              !!PermissionRoleModelAssociation.allFast().find(
                (prma) =>
                  prma.folder_id === fl.folder_id &&
                  prma.permission_role_id === menuFoldersPermissionRole.id
              )
            )
          })
        }

        if (!folderLink) {
          console.warn('FolderLink missing. Maybe blueprint does not have this folder linked anymore', documentBlueprintId)
        }

        return folderLink?.menu_position || 99
      },
    ]
  }

  getDiffData(targetDocumentBlueprintVersionId) {
    return this.constructor
      .api()
      .get(this.url() + '/diff/' + targetDocumentBlueprintVersionId, { save: false })
  }

  prepareDiff(targetDocumentBlueprintVersionId) {
    return DocumentDiffData.api().post(this.url() + '/diff/' + targetDocumentBlueprintVersionId)
  }

  getCurrentVersion(config) {
    return DocumentVersion.api().get(this.url() + '/current-version', config)
  }

  refreshHeadVersion(config) {
    // console.log('refresh head version', this.head_version, this.head_version_id)
    return DocumentVersion.api()
      .get(this.url() + '/head-version', { ...config, save: false })
      .then(async (r) => {
        if (this.head_version_id) {
          const promises = []

          // VIID_TODO:

          /* AnswerDocumentAssociation.query()
            .where((ada) => ada.document_version_id === this.head_version_id)
            .all()
            .forEach((ada) => {
              promises.push(Answer.delete((a) => a.id === ada.answer_id))
              promises.push(ada.$delete())
            }) */
          await Promise.all(promises)
        }
        await r.save()
        const headVersion = r.entities['document-versions'].find(
          (dv) => dv.id === r.response.data.id
        )

        return Document.update({
          where: this.id,
          data: {
            head_version_id: headVersion.id,
          },
        }).then((_d) => {
          return headVersion
        })
      })
    /* return DocumentVersion.api().get(this.url() + '/current-version', { save: false }).then((result) => {
      // result.response.data.document_id = this.id
      result.save()
      return result
    }) */
  }

  refreshEditVersion(config) {
    // console.log('refresh head version', this.head_version, this.head_version_id)
    return DocumentVersion.api()
      .get(this.url() + '/edit-version', { ...config, save: false })
      .then(async (r) => {
        if (this.edit_document_version_id) {
          const promises = []
          /* AnswerDocumentAssociation.query().where(ada => ada.document_version_id === this.edit_document_version_id).all().forEach((ada) => {
          promises.push(Answer.delete(a => a.id === ada.answer_id))
          promises.push(ada.$delete())
        }) */
          promises.push(Answer.deleteAll())

          // needed?
          promises.push(AnswerSectionSelectGroupOptionResultUserData.deleteAll())

          await Promise.all(promises)
        }

        await r.save()

        const editVersion = r.entities['document-versions'].find(
          (dv) => dv.id === r.response.data.id
        )

        return Document.update({
          where: this.id,
          data: {
            edit_document_version: editVersion,
            edit_document_version_id: editVersion.id,
          },
        }).then((_d) => {
          return _d.edit_document_version
        })
      })
  }

  getHeadVersion(config) {
    if (!config && this.head_version_id) {
      const existingHeadVersion = DocumentVersion.query().whereId(this.head_version_id).first()
      if (existingHeadVersion) {
        return Promise.resolve(existingHeadVersion)
      }
    }

    return this.refreshHeadVersion(config)
  }

  cancelUpgrade() {
    return this.constructor.api().post(this.url() + '/cancel-upgrade', { save: false })
  }

  addAllowedCompany(company) {
    return CompanyDocumentTemplateAllotment.api().post(
      this.url() + CompanyDocumentTemplateAllotment.$url(),
      { company_id: company.id }
    )
  }

  removeAllowedCompany(company) {
    const cdta = CompanyDocumentTemplateAllotment.query().where(
      (cdta) => cdta.document_id === this.id && cdta.company_id === company.id
    ).first()
    return cdta.delete()
  }

  getEditVersion(config) {
    if (!config && this.edit_document_version_id) {
      const existingEditVersion = DocumentVersion.query()
        .whereId(this.edit_document_version_id)
        .first()

      if (existingEditVersion) {
        return Promise.resolve(existingEditVersion)
      }
    }

    return this.refreshEditVersion(config)
  }

  finishUpgrade() {
    return this.constructor.api().post(this.url() + '/finish-upgrade', { save: false })
  }

  resolveBlueprint() {
    if (
      this.document_blueprint &&
      this.document_blueprint.id &&
      this.document_blueprint.current_blueprint_version
    ) {
      return Promise.resolve(this.document_blueprint)
    }
    return this.refreshBlueprint()
  }

  refreshBlueprint() {
    return DocumentBlueprint.api().get(this.url() + '/document-blueprint')
  }

  refreshDocumentVersions(params) {
    // const headVersionId = this.head_version_id
    return DocumentVersion.api()
      .get(this.url() + DocumentVersion.$url(), { params, save: false })
      .then(async (result) => {
        result.response.data.forEach((dbv) => {
          dbv.document_id = this.id
        })

        await result.save()
        return result
      })
  }

  getCurrentUpgradeDocumentBlueprintVersion() {
    return DocumentBlueprintVersion.api()
      .get(this.url() + '/current-upgrade-document-blueprint-version')
      .then((r) => {
        return this.updateAttr({
          current_upgrade_document_blueprint_version_id:
            r.entities['document-blueprint-versions'][0].id,
        }).then((wat) => {
          // console.log('wat', wat)
          return r
        })
      })
  }

  getCurrentProgress() {
    return axios.get('/api/documents/' + this.id + '/progress?document_content_locale=' + this.current_locale).then((r) => {
      const data = {
        current_progress: parseInt(r.data.progress),
        upgrade_available: r.data.upgrade_available,
      }
      return Document.update({
        where: this.id,
        data,
      })
    })
  }

  createExport(options, config = {}) {
    options = options || {}

    const params = {}

    if (options.locale) {
      params.locale = options.locale
    }

    if (options.includeComments) {
      params.include_comments = true
    }

    if (options.includeAllOptionItems) {
      params.include_all_option_items = true
    }

    if (options.includeInfoTexts) {
      params.include_info_texts = true
    }

    if (options.excludeHeader) {
      params.exclude_header = true
    }

    if (options.resultsOnly) {
      params.results_only = true
    }

    if (options.fileType) {
      params.export_format = options.fileType
    }

    if (options.exportSync) {
      params.export_sync = true
    }

    if (options.saveToPrimaryFolder) {
      params.save_to_primary_folder = true
    }

    if (options.excludeEmptyAnswers) {
      params.exclude_empty_answers = true
    }

    return File.api().post(
      this.url() + File.$url(),
      {},
      {
        params,
        save: false,
        ...config,
      }
    )
  }

  refreshDocumentInvites() {
    return Invite.api().get(this.url() + '/document-invites')
  }

  async delete() {
    await FolderLink.delete((fl) => fl.document_id === this.id)
    await this.constructor.api().delete(this.url(), { delete: this.id })
  }

  getNavbarItem(folderId) {
    const blueprintId = this.document_blueprint_id
    folderId = folderId || this.primary_folder_id

    const ret = {
      name: this.name,
      nameId: ['document', this.id, folderId, blueprintId, this.company_id].join('-'),
      documentId: this.id,
      icon: 'fas fa-file-alt',
      iconColor: 'var(--v-theme-primary)',
      to: this.getRouterTo(),
      progress: !isNaN(this.current_progress) && parseInt(this.current_progress),
    }

    if (this.upgrade_available) {
      const $t = store.getters['navbar/$t']
      ret.icon = 'fas fa-file-upload'
      ret.iconColor = 'warning'
      ret.iconTooltip = $t('documents.detail.new_version_available')
    } else if (this.current_upgrade_document_blueprint_version_id) {
      const $t = store.getters['navbar/$t']
      ret.icon = 'fas fa-file-edit'
      ret.iconColor = 'error'
      ret.iconTooltip = $t('documents.detail.upgrade_in_progress')
    }

    return ret
  }

  getIcon() {
    return 'fas fa-file-alt'
  }

  getRouterTo() {
    return {
      name: 'documents.detail.page',
      params: {
        documentId: this.id,
        companyId: this.company_id,
        page: 1,
      },
    }

  }

  createPrimaExport({ locale } = {}) {
    const params = {}

    if (locale) {
      params.locale = locale
    }

    return axios.post('/api' + this.url() + '/prima-export', {}, {
      params,
      responseType: 'blob',
    })

  }
  static async loadDocument(documentId) {
    const document = await Document.find(documentId)

    if (document) {
      return document
    }

    return Document.api().get('/documents/' + documentId)
  }

  async refreshUpgradableCreatedFromTemplateDocuments() {
    const result = await Document.api().get(this.url() + '/upgradable-created-from-template-documents')
    return result

  }

  async upgradeCreatedFromTemplateDocuments(documentIds) {
    const result = await Document.api().post(
      this.url() + '/upgradable-created-from-template-documents',
      {
        upgrade: true,
        documentIds
      }
    )
    return result

  }

  static async loadTemplates(companyId) {
    return Document.api().get('/documents', { params: { company_id: companyId, templates: true } })
  }

  static async exportTemplates(companyId, globalOnly = false) {
    return Document.api().post(
      '/documents',
      {},
      {
        params: {
          company_id: companyId,
          templates: true,
          export: true,
          global_only: globalOnly,
        },
        responseType: 'blob',
      },
    )
  }

  async getDocumentAsHtml() {
    return await Document.api().get(this.url() + '/result-html-export')
  }

  getTranslation(key) {
    const i18n = i18nLegacy

    const contentLocale = this.current_locale
    let translation = i18n.global.t(key, contentLocale)

    if (!translation) {
      const selectedCompany = store.getters['navbar/selectedCompany']
      if (selectedCompany) {
        selectedCompany.content_locales
          .filter((companyLocale) => companyLocale !== contentLocale)
          .some((companyLocale) => {
            const val = i18n.global.t(key, companyLocale)
            if (val) {
              translation = val
              return true
            }
          })
      }

      if (!translation) {
        const $t = store.getters['navbar/$t']
        translation = $t(key)
      }
    }

    return translation

  }

  getObjectTranslation(obj) {
    const contentLocale = this.current_locale
    let translation = obj[contentLocale]
    if (!translation) {
      const selectedCompany = store.getters['navbar/selectedCompany']
      if (selectedCompany) {
        selectedCompany.content_locales
          .filter((companyLocale) => companyLocale !== contentLocale)
          .some((companyLocale) => {
            if (obj[companyLocale]) {
              translation = obj[companyLocale]
              return true
            }
          })
        }

      if (!translation) {
        translation = obj[store.getters['auth/currentLocale']]
      }
    }

    return translation || ''

  }
}

Document.entity = 'documents'
