<script>
import {
  debounce,
  groupBy,
  sortBy,
  uniqBy,
} from 'lodash'

import ProfilePicture from '@components/profile-picture'
import IconButton from '@components/icon-button'

import store from '@state/store'

import { Document } from '@state/models/document'
import File from '@state/models/file'
import DocumentBlueprint from '@state/models/document-blueprint'
import ScormFile from '@state/models/scorm-file'
import Folder from '@state/models/folder'
import Company from '@state/models/company'
import { VInfiniteScroll } from 'vuetify/components'
import ModelSearchResult from '@state/models/model-search-result'


let doneCallback = null

export default {
  name: 'PlanitModelSearch',
  components: {
    ProfilePicture,
    IconButton,
    VInfiniteScroll,
  },
  props: {
    placeholder: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: null },
    excludeModels: { type: Array, required: false, default: (_) => [] },
    companyId: { type: Number, required: false, default: null },
    hideSearchInput: { type: Boolean, required: false, default: false },
    hideSwitches: { type: Boolean, required: false, default: false },
    linkInsteadOfPlus: { type: Boolean, required: false, default: false },
    fullHeight: { type: Boolean, required: false, default: false },
    filterModelType: { type: Array, required: false, default: null },
    showCloseButton: { type: Boolean, required: false, default: false },
    includeCompanies: { type: Boolean, required: false, default: false },
    twoColumns: { type: Boolean, required: false, default: false },
    maxHeight: { type: String, required: false, default: '30vh' },
    minWidth: { type: String, required: false, default: '42rem' },
    excludeMenuFolders: { type: Boolean, required: false, default: false },
  },
  emits: ['search', 'set-model', 'close'],
  data() {
    return {
      searchString: null,
      searchResultIds: {},
      filter: {
        documents: false,
        files: false,
        folders: false,
        scorm_files: false,
        document_blueprints: false,
        companies: false,
        answer_content: false,
      },
      blueprintFilter: [],
      searchPromises: {},
      searchLoading: {},
      answerContent: [],
      loading: true, // for initial creation
      answerContentArgs: { page: 0, per_page: 20 },
      startAnswerContentPagination: false,
      sortedSearchKeys: [
        'documents',
        'files',
        'folders',
        'scorm_files',
        'document_blueprints',
        'companies',
        'answer_content'
      ],
      companyPromise: null,
    }
  },
  computed: {
    isAdmin() {
      return store.getters['auth/isAdmin']
    },
    isManager() {
      return store.getters['navbar/isManager']
    },
    excludeModelIdsByEntity() {
      const groupedModels = groupBy(this.excludeModels, (m) => m.constructor.entity)
      const groupedModelIds = {}

      Object.keys(groupedModels).forEach((entity) => {
        groupedModelIds[entity] = groupedModels[entity].map((m) => m.id)
      })

      return groupedModelIds
    },
    searchResults() {
      let results = {}

      if (this.searchResultIds.documents && this.filter.documents) {
        results.documents = sortBy(
          this.searchResultIds.documents
          .filter((mId) => {
            return (
              !this.excludeModelIdsByEntity.documents ||
              this.excludeModelIdsByEntity.documents.indexOf(mId) === -1
            )
          })
          .map((dId) => Document.query().whereId(dId).with('document_blueprint').first())
          .filter((d) => {
            return (
              !this.blueprintFilter.length ||
              this.blueprintFilter.indexOf(d.document_blueprint_id) !== -1
            )
          }),
          d => d?.document_blueprint.menu_position || d?.document_blueprint.name
        )
      }

      if (this.answerContent && this.filter.answer_content) {
        const groupedByDocumentId = groupBy(
          this.answerContent,
          (a) => a.document_id
        )
        const resultsArray = []

        for (let documentId of Object.keys(groupedByDocumentId).sort().reverse()) {
          const group = groupedByDocumentId[documentId]
          const groupedByPage = groupBy(group, (a) => a.document_page)
          let newDocument = { ...group[0], text: [] }

          for (let page in groupedByPage) {
            const pageGroup = groupedByPage[page]
            let contentText = pageGroup.sort((a, b) => a.document_page - b.document_page).flatMap(answer => {
              return ['section_info_text', 'text', 'section_text', 'comment_text', 'section_data', 'data', 'document_blueprint_name'].map(attr => {
                if (answer[attr]) {
                  if (answer.show_position) {
                  }
                  return answer.show_position ? answer.full_position_section + ' ' + answer[attr] : answer[attr]
                }
              }).filter(Boolean)
            })

            const lastAnswerInSection = pageGroup[pageGroup.length - 1]
            newDocument.text.push({
                pageNumber: parseInt(page),
                text: contentText,
                answer_id: lastAnswerInSection ? lastAnswerInSection.id : null,
            })
          }

          resultsArray.push(newDocument)
        }
        results.answer_content = resultsArray
      }

      if (this.searchResultIds.files && this.filter.files) {
        results.files = this.searchResultIds.files
          .filter((mId) => {
            return (
              !this.excludeModelIdsByEntity.files ||
              this.excludeModelIdsByEntity.files.indexOf(mId) === -1
            )
          })
          .map((dId) => File.query().whereId(dId).with('document_version').first())

      }

      if (
        this.searchResultIds.document_blueprints &&
        this.filter.document_blueprints
      ) {
        results.document_blueprints = this.searchResultIds.document_blueprints
          .filter((mId) => {
            return (
              !this.excludeModelIdsByEntity['document-blueprints'] ||
              this.excludeModelIdsByEntity['document-blueprints'].indexOf(mId) === -1
            )
          })
          .map((dId) => DocumentBlueprint.query().whereId(dId).first())
      }

      if (this.searchResultIds.scorm_files && this.filter.scorm_files) {
        results.scorm_files = this.searchResultIds.scorm_files
          .filter((mId) => {
            return (
              !this.excludeModelIdsByEntity['scorm-files'] ||
              this.excludeModelIdsByEntity['scorm-files'].indexOf(mId) === -1
            )
          })
          .map((dId) => ScormFile.query().whereId(dId).first())
      }

      if (this.searchResultIds.folders && this.filter.folders) {
        results.folders = sortBy(
          this.searchResultIds.folders
          .filter((mId) => {
            return (
              !this.excludeModelIdsByEntity.folders ||
              this.excludeModelIdsByEntity.folders.indexOf(mId) === -1
            )
          })
          .map((dId) => Folder.query().whereId(dId).first()),
          Folder.getSortAttrs(),
        )
      }

      if (this.searchResultIds.companies && this.filter.companies) {
        /* results.companies = store.getters['navbar/allCompanies'].filter(
          (c) => c.name.toLowerCase().indexOf(this.value.toLowerCase()) !== -1
        ) */
       results.companies = this.searchResultIds.companies
          .filter((mId) => {
            return (
              !this.excludeModelIdsByEntity.companies ||
              this.excludeModelIdsByEntity.companies.indexOf(mId) === -1
            )
          })
          .map((dId) => Company.query().whereId(dId).first())
      }

      return results

    },
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    blueprintFilterSelectItems() {
      if (this.searchResults?.documents?.length) {
        return uniqBy(
          this.searchResults.documents,
          d => d.document_blueprint.id,
        ).map((d) => {
          return {
            value: d.document_blueprint.id,
            text: d.document_blueprint.name,
          }
        })
      }
    },
    noSearchResults() {
      return !Object.keys(this.searchResults).some(k => !!this.searchResults[k]?.length)
    },
    sortedSearchPromiseKeys()
    {
      return sortBy(Object.keys(this.searchPromises), k => this.sortedSearchKeys.indexOf(k))
    },
    escapedString() {
      return this.searchString ? this.searchString.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : ''
    },
  },
  watch: {
    value(v, ov) {
     if (v !== ov) {
        this.searchString = v
        this.searchDebounced()
      }
    },
  },
  created() {
    this.searchDebounced = debounce(this.search, 500)

    if (this.value) {
      this.searchString = this.value
      this.searchDebounced()

    }

    if (!this.hideSearchInput) {
      this.loading = false

    }
  },
  methods: {
    async loadMore({ done }) {
      doneCallback = done
      this.answerContentArgs.page += 1
        const result = await ModelSearchResult.$all({
          ...this.answerContentArgs,
          search: this.searchString,
          filter_model_type: ['answer_content'],
          company_id: this.companyId,
        })
        this.handleAnswerContentResult(result, done)
    },
    handleAnswerContentResult(result, done) {
      this.searchLoading['answer_content'] = false
      const answerContentData = result.response.data['answer_content']
      if (answerContentData.length) {
        this.answerContent = this.answerContent.concat(this.processAnswerContentData(answerContentData))
        this.searchResultIds['answer_content'] = true
        done('ok')
      } else {
        done('empty')
        if (this.answerContentArgs.page === 1) {
          this.filter['answer_content'] = false
          this.searchResultIds['answer_content'] = false
        }
      }
    },
    processAnswerContentData(answerContentData) {
      return answerContentData.map((answer) => ({
        ...answer,
        text: this.extractContext(answer.text),
        section_text: this.extractContext(answer.section_text),
        comment_text: this.extractContext(answer.comment_text),
        section_info_text: this.extractContext(answer.section_info_text),
        data: this.getCorrectDataItem(answer.data),
        section_data: this.getCorrectDataItem(answer.section_data),
        constructor: { entity: 'answer_content' },
        getIcon() {
          return 'fal fa-file-alt'
        },
      }))
    },
    async search() {
      this.$emit('search', this.searchString)

      if (this.searchString?.length > 1) {

        const args = {
          search: this.searchString,
        }

        Object.keys(this.filter).forEach((filterKey) => {
          this.filter[filterKey] = false
        })

        if (this.companyId) {
          args.company_id = this.companyId

          this.filter.documents = true
          this.filter.files = true
          this.filter.folders = true
          this.filter.scorm_files = true
          this.filter.answer_content = true
        }

        if (this.isAdmin || this.isManager) {
          this.filter.document_blueprints = true
        }

        this.searchResultIds = {}
        this.answerContent = []

        // is handled separately below
        let modelTypes = Object.keys(this.filter)
          .filter(k => k !== 'companies' && (k !== 'document_blueprints' || (this.isAdmin || this.isManager)))

        if (this.filterModelType) {
          args.filter_model_type = this.filterModelType
          modelTypes = this.filterModelType
        }

        const promises = this.searchPromises = {}
        modelTypes.forEach((modelType) => {
          this.searchLoading[modelType] = true
          if (modelType === 'answer_content') {
            this.startAnswerContentPagination = true
            this.answerContentArgs.page = 0

            promises[modelType] = Promise.resolve()

            if (doneCallback)
              doneCallback('ok')

          } else {
            const extraArgs = {}
            if (this.excludeMenuFolders && modelType === 'folders') {
              extraArgs.exclude_menu_folders = true
            }
            promises[modelType] = ModelSearchResult.$all({
              ...args,
              ...extraArgs,
              filter_model_type: [modelType],
            })
          }
        })

        if (this.includeCompanies) {
          this.companyPromise = Company.$all({
            // include_license_type: true,
            search: this.searchString,
          }).then((result) => {
            this.searchResultIds.companies = result.response.data.map((c) => c.id)
          })
          this.filter.companies = true
        }
        // const result = await Promise.all(Object.values(promises))

        promises.documents?.then((result) => {
          this.searchLoading['documents'] = false
          if (result.response.data.documents.length) {
            this.searchResultIds['documents'] = result.response.data.documents.map((d) => d.id)

          } else {
            this.filter.documents = false

          }
        })

        promises.files?.then((result) => {
          this.searchLoading['files'] = false
          if (result.response.data.files.length) {
            this.searchResultIds.files = result.response.data.files.map((f) => f.id)

          } else {
            this.filter.files = false

          }
        })

        promises.document_blueprints?.then((result) => {
          this.searchLoading['document_blueprints'] = false
          if (result.response.data.document_blueprints.length) {
            this.searchResultIds['document_blueprints'] = result.response.data.document_blueprints.map((d) => d.id)

          } else {
            this.filter.document_blueprints = false

          }
        })

        promises.scorm_files?.then((result) => {
          this.searchLoading['scorm_files'] = false
          if (result.response.data.scorm_files.length) {
            this.searchResultIds.scorm_files = result.response.data.scorm_files.map((sf) => sf.id)

          } else {
            this.filter.scorm_files = false

          }
        })

        promises.folders?.then((result) => {
          this.searchLoading['folders'] = false
          if (result.response.data.folders.length) {
            this.searchResultIds.folders = result.response.data.folders.map((f) => f.id)

          } else {
            this.filter.folders = false

          }
        })

        /* if (this.includeCompanies) {
          this.companyPromise.then(() => {
            if (!this.searchResults.companies?.length) {
              this.searchResultIds.companies = false
            }
          })
        } */

        Promise.any(Object.values(promises)).then(() => {
          this.loading = false
        })

        Promise.allSettled(Object.values(promises))

      } else {
        this.searchResultIds = {}
        this.loading = false

      }

    },
    getMatch(text, escapedString) {
      const regex = new RegExp(`${escapedString}`, 'i')
      return regex.exec(text)
    },
    extractContext(text) {
      const escapedString = this.escapedString
      const match = this.getMatch(text, escapedString)
      if (!match) return ''

      const words = text.split(/\s+/)
      const startWordIndex = text.substring(0, match.index).split(/\s+/).length - 1
      const endWordIndex = startWordIndex + escapedString.split(/\s+/).length

      let context = words.slice(
        Math.max(startWordIndex - 10, 0),
        Math.min(endWordIndex + 10, words.length)
      ).join(' ')

      if (startWordIndex > 10) {
        context = '... ' + context
      }
      if (endWordIndex < words.length - 10) {
        context = context + ' ...'
      }

      return context
    },
    getCorrectDataItem(data) {
      if (!data || !Array.isArray(data['items'])) return null

      const escapedString = this.escapedString

      for (let item of data['items']) {
        if (item && item['text_translations']) {
          const match = this.getMatch(item['text_translations'][this.currentLocale], escapedString)
          if (match)  {
            return item['text_translations'][this.currentLocale]
          }
        }

        if (item && item.custom_input_text) {
          const match = this.getMatch(item.custom_input_text[this.currentLocale], escapedString)
          if (match)  {
            return item.custom_input_text[this.currentLocale]
          }
        }
      }
      return null
    },
    highlightSearchString(text) {
      const escapedString = this.escapedString
      const words = escapedString.split(' ')

      words.forEach(word => {
        text = text.replace(new RegExp(`(${word})`, 'ig'), '<strong>$1</strong>')
      })

      return text
    },
    hasAnswerTextData(textArray) {
      return textArray.some(pageData => pageData.text && pageData.text.length > 0);
    },
    resetSearch() {
      this.searchString = null

      // this.$emit('search', this.userSearchString)
    },
    async handleSetModel(model) {
      await this.$emit('set-model', model)
    },
  },
}
</script>

<template>
  <div>
    <div v-if="!loading" class="d-flex">
      <div class="flex-grow-1">
        <form v-if="!hideSearchInput">
          <planitTextField
            v-model="searchString"
            :label="placeholder"
            variant="outlined"
            density="compact"
            hide-details
            prepend-inner-icon="fal fa-fw fa-search"
            @update:model-value="searchDebounced"
          ></planitTextField>
        </form>

        <div
          v-if="
            !hideSwitches && (
            searchResultIds.documents ||
            searchResultIds.files ||
            searchResultIds.document_blueprints ||
            searchResultIds.scorm_files ||
            searchResultIds.folders ||
            searchResultIds.companies ||
            searchResultIds.answer_content )
          "
          class="pa-4 d-flex align-center flex-wrap ga-6 pr-6"
        >
          <PlanitSwitch
            v-if="searchResultIds.companies"
            v-model="filter.companies"
            :label="$t('generic.companies')"
            variant="flat"
            inset
            hide-details
            density="compact"
          ></PlanitSwitch>

          <PlanitSwitch
            v-if="searchResultIds.documents"
            v-model="filter.documents"
            :label="$t('generic.documents')"
            variant="flat"
            inset
            hide-details
            density="compact"
          ></PlanitSwitch>

          <PlanitSwitch
            v-if="searchResultIds.answer_content"
            v-model="filter.answer_content"
            :label="$t('generic.document_content')"
            variant="flat"
            inset
            hide-details
            density="compact"
          ></PlanitSwitch>

          <PlanitSwitch
            v-if="searchResultIds.files"
            v-model="filter.files"
            :label="$t('generic.files')"
            variant="flat"
            inset
            hide-details
            density="compact"
          ></PlanitSwitch>

          <PlanitSwitch
            v-if="searchResultIds.document_blueprints"
            v-model="filter.document_blueprints"
            :label="$t('components.nav_bar.document_blueprints')"
            variant="flat"
            inset
            hide-details
            density="compact"
          ></PlanitSwitch>

          <PlanitSwitch
            v-if="searchResultIds.scorm_files"
            v-model="filter.scorm_files"
            :label="$t('admin.scorm_files.scorm_files')"
            variant="flat"
            inset
            hide-details
            density="compact"
          ></PlanitSwitch>

          <PlanitSwitch
            v-if="searchResultIds.folders"
            v-model="filter.folders"
            :label="$t('generic.folders')"
            variant="flat"
            inset
            hide-details
            density="compact"
          ></PlanitSwitch>

        </div>
      </div>

      <div v-if="showCloseButton" class="ml-auto pa-2">
        <IconButton
          icon="fa-times"
          @click="$emit('close')"
        ></IconButton>
      </div>
    </div>

    <div v-if="searchString">
      <div
        v-if="Object.keys(searchPromises).length || (searchResults.companies && searchResults.companies.length)"
        :style="fullHeight ? '' : 'max-height: ' + maxHeight + '; overflow-y: auto' + '; min-width: ' + minWidth"
        style="background-color: white;"
        class="pb-4 pt-1"
      >
        <div v-if="noSearchResults" class="px-4 pt-4">
          {{ $t('components.planit_model_search.no_results') }}
        </div>
        <v-list
          v-if="filter.companies && (searchResults.companies && searchResults.companies.length)"
          key="companies"
          class="ma-0 pa-0"
          style="background-color: transparent !important;"
        >
          <div class="py-2 pt-4 px-4 d-flex align-center">
            <h3>{{ $t('generic.companies') }}</h3>
            <div class="border-bottom ml-2 flex-grow-1"></div>
          </div>
          <v-row no-gutters>
            <v-col
              :cols="twoColumns ? 6 : 12"
              v-for="company in searchResults.companies"
              :key="'c-' + company.id"
            >
              <v-list-item
                class="pa-0"
              >
                  <component
                    :is="linkInsteadOfPlus ? 'router-link' : 'div'"
                    :to="linkInsteadOfPlus ? { name: 'company.dashboard', params: { companyId: company.id } } : null"
                    class="py-1"
                  >
                    <div
                      class="d-flex align-center justify-space-between"
                      @click="(model) => linkInsteadOfPlus ? handleSetModel(model) : null"
                    >
                      <ProfilePicture
                        :model="company"
                        placeholder-type="company"
                        width="2.5rem"
                        rounded
                        class="mx-2"
                      ></ProfilePicture>

                      <div>
                        <span class="font-weight-medium">{{ company.name }}</span>
                      </div>

                      <div class="flex-grow-1"></div>

                      <IconButton
                        v-if="!linkInsteadOfPlus"
                        icon="fa-plus"
                        class="ml-2"
                        color="primary"
                        @click="handleSetModel(model)"
                      ></IconButton>

                    </div>
                  </component>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list>
          <v-list
            v-for="k in sortedSearchPromiseKeys"
            :key="k"
            class="ma-0 pa-0"
            style="background-color: transparent !important;"
          >
            <v-fade-transition>
              <div v-if="!!filter[k]">
                <div class="py-2 pt-4 px-4 d-flex align-center">
                  <h3>

                    <span v-if="k === 'documents'">
                      {{ $t('generic.documents') }}
                    </span>
                    <span v-if="k === 'files'">
                      {{ $t('generic.files') }}
                    </span>
                    <span v-if="k === 'document_blueprints'">
                      {{ $t('components.nav_bar.document_blueprints') }}
                    </span>
                    <span v-if="k === 'scorm_files'">
                      {{ $t('admin.scorm_files.scorm_files') }}
                    </span>
                    <span v-if="k === 'folders'">
                      {{ $t('generic.folders') }}
                    </span>
                    <span v-if="k === 'answer_content'">
                      {{ $t('generic.document_content') }}
                    </span>
                  </h3>
                  <div class="border-bottom ml-2 flex-grow-1"></div>
                </div>

                <v-progress-linear
                  v-if="searchLoading[k] && k !== 'answer_content'"
                  indeterminate
                ></v-progress-linear>

                <div v-if="searchResults[k]">
                 <component :is="k == 'answer_content'? 'VInfiniteScroll' : 'div'"
                  v-if="searchResults[k] || (k === 'answer_content' && startAnswerContentPagination)"
                  @load="loadMore">
                  <v-row no-gutters>
                    <template
                      v-for="model in searchResults[k]"
                      :key="model.constructor.entity + '-' + model.id"
                    >
                      <v-col
                        v-if="model && (model.constructor.entity !== 'answer_content' || hasAnswerTextData(model.text))"
                        :cols="twoColumns && k !== 'answer_content' ? 6 : 12"
                      >
                        <v-list-item
                          class="pa-0"
                        >
                          <component
                            :is="linkInsteadOfPlus && k !== 'answer_content' && !model.link ? 'router-link' : model.link ? 'a' : 'div'"
                            :to="linkInsteadOfPlus && k !== 'answer_content' && !model.link ? model.getRouterTo(companyId, k) : null"
                            :href="model.link ? model.link : null"
                            class="py-1"
                          >
                              <div
                                class="d-flex align-baseline justify-space-between pr-2"
                                :class="k === 'answer_content' ? 'align-baseline' : 'align-center'"
                                @click="(model) => (linkInsteadOfPlus && k !== 'answer_content') ? handleSetModel(model) : null"
                              >
                                <ProfilePicture
                                  :model="model"
                                  :placeholder-icon="model.getIcon()"
                                  placeholder-type="custom"
                                  width="2.5rem"
                                  rounded
                                  class="mx-2"
                                ></ProfilePicture>

                                <div
                                  v-if="model.constructor.entity === 'documents'"
                                >
                                  <div class="text-grey-darken-2">
                                    {{ model.document_blueprint.name }}
                                  </div>
                                  <div class="mt-1">
                                    <span style="font-weight: 600;">{{ model.primary_folder_name }}</span>
                                  </div>
                                  <div v-if="model.name !== model.document_blueprint.name" class="mt-1">
                                    {{ model.name }}
                                  </div>
                                </div>

                                <div v-if="model.constructor.entity === 'files'">
                                  <span>{{ model.document_name || model.filename }}</span>
                                </div>

                                <div v-if="model.constructor.entity === 'document-blueprints'">
                                  <span>{{ model.name }}</span>
                                </div>

                                <div v-if="model.constructor.entity === 'scorm-files'">
                                  <span>{{ model.name_translations[currentLocale] }}</span>
                                </div>

                                <div v-if="model.constructor.entity === 'folders'">
                                  <span>{{ model.full_path }}</span>
                                </div>

                                <div
                                  v-if="model.constructor.entity === 'answer_content'"
                                  class="my-2"
                                >
                                  <div class="text-grey-darken-2">
                                    {{ model.document_primary_folder_name }} // {{ model.document_blueprint_name }}
                                  </div>
                                  <div
                                    v-for="pageData in model.text"
                                    :key="pageData.answer_id"
                                    class="html-container"
                                  >
                                    <div
                                    v-for="text, index in pageData.text"
                                    :key="index"
                                    class="ml-3 mt-2 d-flex align-center"
                                    >
                                      <span class="mr-1">•</span>
                                      <router-link
                                      :to="{
                                        name: 'documents.detail.page',
                                        params: {
                                          companyId: model.company_id,
                                          documentId: model.document_id,
                                          page: pageData.pageNumber,
                                          },
                                          query: {
                                            result: pageData.answer_id,
                                            searchText: escapedString,
                                          }
                                        }"
                                      >
                                        <span v-html="highlightSearchString(text)"></span>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                                <div class="flex-grow-1"></div>

                                <IconButton
                                  v-if="!linkInsteadOfPlus"
                                  icon="fa-plus"
                                  class="ml-2"
                                  color="primary"
                                  @click="handleSetModel(model)"
                                ></IconButton>

                              </div>
                            </component>
                        </v-list-item>
                      </v-col>
                    </template>
                  </v-row>
                </component>
                </div>
              </div>
            </v-fade-transition>
          </v-list>
      </div>
    </div>
  </div>
</template>
