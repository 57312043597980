<script>
import PlanitProgress from '@components/planit-progress'
import PlanitInput from '@components/form/input'
import IconButton from '@components/icon-button'

import store from '@state/store'

import { FolderType, FileExportStatus, FileType, DocumentLockMode } from '@enums'


export default {
  name: 'PlanitFile',
  components: {
    PlanitProgress,
    PlanitInput,
    IconButton,
  },
  props: {
    file: { type: Object, required: false, default: null },
    folder: { type: Object, required: false, default: null },
    linkText: { type: String, required: false, default: null },
    linkMutedText: { type: String, required: false, default: null },
    to: { type: [String, Object], required: false, default: null },
    document: { type: Object, required: false, default: null },
    icon: { type: String, required: false, default: null },
    canEdit: { type: Boolean, required: false, default: false },
    onChange: { type: Function, required: false, default: null },
    showNameInput: { type: Boolean, required: false, default: false },
    showPrimaryFolderName: { type: Boolean, required: false, default: false },
    showBlueprintName: { type: Boolean, required: false, default: false },
    asCard: { type: Boolean, required: false, default: false },
    bold: { type: Boolean, required: false, default: false },
  },
  data: function () {
    return {
      FileExportStatus,
      FileType,
      FolderType,
      hasValidationError: false,
    }
  },
  computed: {
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    fileDocumentNameComputed() {
      const LENGTH_THRESHOLD = 60
      const documentName = this.file && (this.file.document_name || this.file.filename)
      if (this.file && documentName) {
        return documentName.length < LENGTH_THRESHOLD
          ? documentName
          : documentName.substr(0, LENGTH_THRESHOLD) + '...'
      }
      return ''
    },
    canEditFolder() {
      if (this.folder) {
        return this.canEdit && this.folder.folder_type === FolderType.COMPANY
      }
      return false
    },
    folderIsMenuFolder() {
      return (
        this.folder &&
        (!!this.folder.template_folder_id ||
          this.folder.folder_type === FolderType.BLUEPRINT_MENU_STRUCTURE)
      )
    },
    folderClasses() {
      if (this.folderIsMenuFolder && !this.folder.icon && !this.folder.image) {
        return ['document-folder-menu']
      }
      return []
    },
    folderIcon() {
      if (this.icon) {
        return this.icon
      } else if (this.folder.icon) {
        return 'fas fa-fw ' + this.folder.icon
      } else if (this.folderIsMenuFolder) {
        return 'fal fa-fw fa-folder'
      } else {
        return 'fal fa-fw fa-folder'
      }
    },
    documentTo() {
      if (this.document && this.document.lock_mode != DocumentLockMode.DELETING) {
        return {
          name: 'documents.detail.page',
          params: {
            documentId: this.document.id,
            companyId: this.document.company_id,
            page: 1,
          },
        }
      }

      return ''
    },
  },
  created() {},
  methods: {
    async saveName() {
      if (this.hasValidationError) {
        return
      }

      await store.dispatch('syncQueue/handleQueueNow')

      if (this.onChange) {
        if (this.folderId) {
          this.onChange({ folderId: this.folder.id })
        } else {
          this.onChange()
        }
      }
    },
  },
}
</script>

<template>
  <div>
    <div>
      <!-- Folder -->
      <component
        v-if="folder"
        :is="showNameInput ? 'div' : 'router-link'"
        :to="to ? to : ''"
      >
        <div class="d-flex align-center file-item">
          <PlanitIcon
            v-if="folder.icon || !folder.image || icon"
            color="primary"
            class="mr-2 flex-shrink-0"
            :class="folderClasses"
            style="font-size: 2rem;"
            :icon="folderIcon"
          >
          </PlanitIcon>

          <div
            v-if="folder.image"
            class="d-flex align-center mr-2"
          >
            <img
              :src="'/api/images/' + folder.image + '.png'"
              style="width: auto; height: 30px"
            />
          </div>

          <h4
            v-if="!showNameInput"
            class="filename"
            :class="bold ? 'font-weight-medium' : ''"
          >
            {{ folder.name }}
          </h4>

          <div
            v-if="showNameInput"
            class="d-flex align-center flex-grow-1"
            @click=""
          >
            <form
              class="flex-grow-1"
              @submit.prevent="saveName"
            >
              <PlanitInput
                :model="folder"
                attr="name"
                :label="$t('generic.name')"
                highlight
                class="mr-2"
                @click.stop=""
                @hasValidationError="hasValidationError = $event"
              ></PlanitInput>
            </form>

            <IconButton
              icon="fa-save"
              :disabled="hasValidationError"
              @click="saveName"
            ></IconButton>
          </div>
        </div>
      </component>

      <!-- Custom link/folder -->
      <router-link
        v-if="linkText && to"
        :to="to"
      >
        <v-sheet class="d-flex align-center file-item">
          <PlanitIcon
            color="primary"
            class="mr-2"
            style="font-size: 2rem"
            :icon="icon || 'far fa-fw fa-folder'"
          ></PlanitIcon>

          <!--{{ linkMutedText }}
          <h4 class="filename">{{ linkText }}</h4>-->
          <div class="d-flex flex-column">
            <div class="text-grey">{{ linkMutedText }}</div>
            <div>{{ linkText }}</div>
          </div>
        </v-sheet>
      </router-link>

      <!-- File -->
      <component
        v-if="file"
        :is="showNameInput ? 'div' : 'router-link'"
        :to="file.link"
        target="_blank"
      >
        <div class="file-item d-flex align-center">
          <PlanitIcon
            color="primary"
            class="mr-1 flex-shrink-0"
            style="font-size: 2rem"
            :icon="icon || file.getIcon('fal') + ' fa-fw'"
          ></PlanitIcon>

          <div class="flex-grow-1">
            <div
              v-if="file.file_type === FileType.DOCUMENT_EXPORT"
              style="font-size: 80%"
              class="text-grey"
            >
              <span v-if="file.document_version">Version {{ file.document_version.version }}</span>
              <span v-if="file.document_version" class="px-2">|</span>
              <span>{{ $filters.formatDate(file.created ,'PP p') }}</span>
            </div>

            <div
              v-if="!showNameInput"
              class="d-flex flex-column"
            >
              <div
                v-if="linkMutedText"
                class="text-grey"
              >
                {{ linkMutedText }}
              </div>
              <h4 class="filename">{{ fileDocumentNameComputed }}</h4>
            </div>

            <div
              v-if="showNameInput"
              class="d-flex align-center flex-grow-1"
              @click=""
            >
              <form
                class="flex-grow-1"
                @submit.prevent="saveName"
              >
                <PlanitInput
                  :model="file"
                  attr="document_name"
                  :label="$t('generic.name')"
                  highlight
                  class="mr-2"
                  @hasValidationError="hasValidationError = $event"
                  @click=""
                ></PlanitInput>
              </form>

              <IconButton
                icon="fa-save"
                :disabled="hasValidationError"
                @click="saveName"
              ></IconButton>
            </div>

            <!--<div class="flex-grow-1"></div>-->

            <!--<div class="d-flex align-center">
              <span class="px-2">{{ file.created | date('PP p') }}</span>
              <span v-if="file.filesize">
                <span>|</span>
                <span class="px-2">{{ Math.floor(file.filesize / 1024) }} KiB</span>
              </span>
            </div>-->

            <div
              v-if="file.export_status === FileExportStatus.PROCESSING"
              class="py-2 ml-auto"
            >
              <v-progress-linear
                indeterminate
                class="mb-2"
              ></v-progress-linear>
              <PlanitChip color="info">
                {{ $t('enums.processing') }}
              </PlanitChip>
            </div>
          </div>
        </div>
      </component>

      <!-- Document -->
      <component
        v-if="document"
        :is="showNameInput ? 'div' : 'router-link'"
        :to="documentTo"
      >
        <div class="file-item d-flex align-center">
          <!-- Upgrade available -->
          <div
            v-if="
              document.upgrade_available && !document.current_upgrade_document_blueprint_version_id
            "
            class="mr-2"
          >
            <PlanitProgress
              :progress="parseInt(document.current_progress)"
              :icon="'fal fa-file-upload'"
              icon-color="warning"
              subscript
            ></PlanitProgress>
          </div>

          <!-- Upgrade started -->
          <div
            v-if="
              !document.upgrade_available && document.current_upgrade_document_blueprint_version_id
            "
            class="mr-2"
          >
            <PlanitProgress
              :progress="parseInt(document.current_progress)"
              :icon="'fal fa-file-edit'"
              icon-color="error"
              subscript
            ></PlanitProgress>
          </div>

          <!-- Default -->
          <div
            v-if="
              !document.upgrade_available && !document.current_upgrade_document_blueprint_version_id && !asCard
            "
            class="mr-2"
          >
            <PlanitProgress
              :progress="parseInt(document.current_progress)"
              icon="fal fa-file-alt"
              icon-color="primary"
              subscript
            ></PlanitProgress>
          </div>
          <div
            v-if="!document.upgrade_available && !document.current_upgrade_document_blueprint_version_id && asCard"
          >
          <PlanitIcon
            color="primary"
            class="mr-2"
            style="font-size: 2rem"
            :icon="icon || 'fal fa-file-alt'"></PlanitIcon>
          </div>

          <div v-if="!showNameInput">
            <div
              v-if="
                showPrimaryFolderName &&
                document.primary_folder_name &&
                document.primary_folder_name  != (document.name_translations[currentLocale] || document.name)
              "
              class="text-grey-darken-3"
            >
              {{ document.primary_folder_name  }}
            </div>
            <div :style="showPrimaryFolderName || showBlueprintName ? { 'font-weight': 600 } : {}" v-if="!asCard">
              {{ document.name_translations[currentLocale] || document.name }}
            </div>
            <div
              v-if="
                showBlueprintName &&
                (
                  document.document_blueprint.name_translations[currentLocale] ||
                  document.document_blueprint.name
                ) != (document.name_translations[currentLocale] || document.name)
              "
              class="text-grey"
            >
              {{
                document.document_blueprint.name_translations[currentLocale] ||
                document.document_blueprint.name
              }}
            </div>
            <div v-if="asCard">
              <h4>
                {{
                  document.document_blueprint?.name_translations[currentLocale] ||
                  document.document_blueprint?.name
                }}
              </h4>
            </div>
          </div>

          <div
            v-if="showNameInput"
            class="d-flex align-center flex-grow-1"
            @click.prevent=""
          >
            <form
              class="flex-grow-1"
              @submit.prevent="saveName"
            >
              <PlanitInput
                :model="document"
                attr="name"
                :label="$t('generic.name')"
                highlight
                class="mr-2"
                @hasValidationError="hasValidationError = $event"
                @click.prevent=""
              ></PlanitInput>
            </form>

            <IconButton
              icon="fa-save"
              :disabled="hasValidationError"
              @click.prevent="saveName"
            ></IconButton>
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<style>
.filename {
  font-weight: 500;
  word-break: break-word;
}

.file-item .PlanitIcon {
  width: 2.5rem;
  /* min-height: 5rem; */
}
</style>
