import axios from 'axios'
import Vue from 'vue'
import { createStore } from 'vuex';
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import vuetify from '../plugins/vuetify'

import User from './models/user'
import Address from './models/address'
import DocumentBlueprint from './models/document-blueprint'
import {
  DocumentBlueprintVersion,
} from './models/document-blueprint-version'
import Commit from './models/commit'
import Section from './models/section'
import Company from './models/company'
import CompanyRole from './models/company-role'
import { Document, DocumentDiffData } from './models/document'
import { DocumentVersion } from './models/document-version'
import Answer from './models/answer'
import CompanyDocumentTemplateAllotment from './models/company-document-template-allotment'
import File from './models/file'
import Folder from './models/folder'
import DocsAuditProject from './models/audit/docs-audit-project'
import DocsAuditProjectQuestion from './models/audit/docs-audit-project-question'
import ScormFile from './models/scorm-file'
import ScormFileCategory from '@state/models/scorm-file-category'
import ScormFileCompanyAssociation from './models/scorm-file-company-association'
import ScormFileCategoryAssociation from '@state/models/scorm-file-category-association'
import ScormFileCompanyBooking from './models/scorm-file-company-booking'
import CompanyRoleScormStatus from './models/company-role-scorm-status'
import Invite from './models/invite'
import {
  SelectGroup,
  SelectGroupOption,
} from './models/select-group'
import FileFolderDocumentBlueprintAssociation from './models/file-folder-document-blueprint-association'
import {
  SectionSelectGroupOptionResult,
} from './models/section-select-group-option-result'
import {
  AnswerSectionSelectGroupOptionResultUserData,
} from './models/answer-section-select-group-option-result-user-data'
import Changelog from './models/changelog'
import FolderLink from './models/folder-link'
import Vendor from './models/vendor'
import FolderVendorAssociation from './models/folder-vendor-association'
import BrandSetting from './models/brand-setting'
import CompanyPerson from './models/company-person'
import CompanyPersonType from './models/company-person-type'
import Condition from './models/condition'
import License from './models/license'
import PermissionRole from './models/permission-role'
import PermissionRoleUserAssociation from './models/permission-role-user-association'
import PermissionRoleCompanyAssociation from './models/permission-role-company-association'
import PermissionRoleCompanyRoleAssociation from './models/permission-role-company-role-association'
import PermissionRoleModelAssociation from './models/permission-role-model-association'
import ModelSearchResult from './models/model-search-result'
import Checklist from './models/checklist'
import ChecklistStep from './models/checklist-step'
import TosAcceptance from './models/tos-acceptance'
import ChecklistUserActivation from './models/checklist-user-activation'
import InviteCode from './models/invite-code'
import Order from './models/order'
import Basket from './models/basket'
import Event from './models/event'
import Reminder from './models/reminder'
import ReminderUserAssociation from './models/reminder-user-association'
import Approval from './models/approval'
import SectionReferenceItem from './models/section-reference-item'
import Promotion from './models/promotion'
import DownloadResource from './models/download-resource'
import Task from './models/task'
import TaskCompanyRoleAssociation from './models/task-company-role-association'
import ApprovalComment from './models/approval-comment'
import RepositoryReferenceItem from './models/repository-reference-item'
import Timer from './models/timer'
import BlogCategory from './models/blog-category'
import BlogCategoryAssociation from './models/blog-category-association'
import TableColumn from './models/table-column'
import TableSetting from './models/table-setting'
import TableColumnSettingAssociation from './models/table-column-setting-association'
import TableSettingActivation from './models/table-setting-activation'
import Tag from './models/tag'
import FolderTagAssociation from './models/folder-tag-association'
import PrimaFolderTemplate from './models/prima-folder-template'
import PrimaFolderTemplateAssociation from './models/prima-folder-template-association'
import MenuFolderSetting from './models/menu-folder-setting'

import modules from './modules'
import Rollout from './models/rollout'
import Recipient from './models/recipient'
import RolloutFileAssociation from './models/rollout-file-association'
import ChecklistStepFolderStatus from './models/checklist-step-folder-status'
import SessionScormLink from './models/session-scorm-link'
import TrainingSession from './models/training-session'
import TrainingSessionCompanyRoleAssociation from './models/training-session-company-role-association'
import CompanyLink from './models/company-link'
import MailConfig from './models/mail-config'
import StandardBlueprintFolderAssociation from './models/standard-blueprint-folder-association'

const database = new VuexORM.Database()

database.register(User)
database.register(Address)

database.register(DocumentBlueprint)
database.register(DocumentBlueprintVersion)

database.register(Section)

database.register(Document)
database.register(DocumentDiffData)
database.register(DocumentVersion)
database.register(CompanyDocumentTemplateAllotment)

database.register(Answer)

database.register(Commit)

database.register(Company)
database.register(CompanyRole)
database.register(File)
database.register(Folder)

database.register(DocsAuditProject)
database.register(DocsAuditProjectQuestion)

database.register(ScormFile)
database.register(ScormFileCategory)
database.register(ScormFileCompanyAssociation)
database.register(ScormFileCategoryAssociation)
database.register(CompanyRoleScormStatus)
database.register(ScormFileCompanyBooking)

database.register(Invite)

database.register(SelectGroup)
database.register(SelectGroupOption)

database.register(FileFolderDocumentBlueprintAssociation)

database.register(SectionSelectGroupOptionResult)

database.register(AnswerSectionSelectGroupOptionResultUserData)

database.register(Changelog)
database.register(FolderLink)

database.register(Vendor)
database.register(FolderVendorAssociation)
database.register(BrandSetting)

database.register(CompanyPerson)
database.register(CompanyPersonType)

database.register(Condition)

database.register(License)
database.register(PermissionRole)
database.register(PermissionRoleUserAssociation)
database.register(PermissionRoleCompanyAssociation)
database.register(PermissionRoleCompanyRoleAssociation)
database.register(PermissionRoleModelAssociation)

database.register(ModelSearchResult)
database.register(Checklist)
database.register(ChecklistStep)

database.register(TosAcceptance)

database.register(ChecklistUserActivation)
database.register(InviteCode)

database.register(Order)
database.register(Basket)
database.register(Event)
database.register(Reminder)
database.register(ReminderUserAssociation)
database.register(SectionReferenceItem)

database.register(Approval)
database.register(ApprovalComment)
database.register(TaskCompanyRoleAssociation)
database.register(Task)
database.register(Timer)

database.register(Promotion)
database.register(DownloadResource)
database.register(RepositoryReferenceItem)

database.register(BlogCategory)
database.register(BlogCategoryAssociation)

database.register(TableColumn)
database.register(TableSetting)
database.register(TableColumnSettingAssociation)
database.register(TableSettingActivation)
database.register(Tag)
database.register(FolderTagAssociation)
database.register(PrimaFolderTemplate)
database.register(PrimaFolderTemplateAssociation)
database.register(MenuFolderSetting)

database.register(Rollout)
database.register(Recipient)
database.register(RolloutFileAssociation)
database.register(ChecklistStepFolderStatus)

database.register(SessionScormLink)
database.register(TrainingSession)
database.register(TrainingSessionCompanyRoleAssociation)
database.register(CompanyLink)
database.register(MailConfig)

database.register(StandardBlueprintFolderAssociation)

VuexORM.use(VuexORMAxios, {
  axios,
  database,
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const plugins = [
  VuexORM.install(database),
]

const store = createStore({
  namespaced: true,
  modules,
  plugins,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})
store.vuetify = vuetify

export default store
