import '@mdi/font/css/materialdesignicons.css'

import router from '@router'

import * as Sentry from '@sentry/vue'

import store from '@state/store'

import LawHtml from '@src/directives/law-html'

import { createApp, configureCompat } from 'vue'

import VueCookies from 'vue-cookies'

import appConfig from './app.config'
import App from './app.vue'

import i18n from './plugins/vue-i18n'

import vuetify from './plugins/vuetify'
import formatDate from '@utils/format-date'
import formatCurrency from  '@utils/currency-filter'

import PlanitAutocomplete from './components/vuetify/planit-autocomplete.vue'
import PlanitSelect from './components/vuetify/planit-select.vue'
import PlanitCheckbox from './components/vuetify/planit-checkbox.vue'
import PlanitDialog from './components/vuetify/planit-dialog.vue'
import PlanitIcon from './components/vuetify/planit-icon.vue'
import PlanitButton from './components/vuetify/planit-button.vue'
import PlanitSwitch from './components/vuetify/planit-switch.vue'
import PlanitChip from './components/vuetify/planit-chip.vue'
import planitTextField from './components/vuetify/planit-text-field.vue'

// import { load as axiosNProgressLoad } from '@utils/axios-nprogress'
// Don't warn about using the dev version of Vue in development.
const instanceMode = process.env.INSTANCE_MODE || window.instance_mode

// Create Vue app instance
const app = createApp(App)
app.config.globalProperties.$cookies = VueCookies
app.config.globalProperties.$filters = {
  formatDate,
  formatCurrency,
}


// app.config.productionTip = process.env.NODE_ENV === 'production'
app.config.productionTip = false
app.config.devtools = false

// enable once privacy policy is updated
if (false) {
  const sentryDsn =
    instanceMode === 'production' ? appConfig.sentry_dsn : appConfig.sentry_dsn_debug

  console.log('sentryDsn', sentryDsn)

  Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ["localhost", /^https:\/\/www\.planitprima\.com\/api/],
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.directive('LawHtml', LawHtml)

// If running inside Cypress...
// if (process.env.VUE_APP_TEST === 'e2e') {
//   // Ensure tests fail when Vue emits an error.
//   app.config.errorHandler = window.Cypress.cy.onUncaughtException
// }

app.use(store)
app.use(router)
app.use(vuetify)
app.use(VueCookies)
app.use(i18n)


app.component('PlanitAutocomplete', PlanitAutocomplete)
app.component('PlanitSelect', PlanitSelect)
app.component('PlanitCheckbox', PlanitCheckbox)
app.component('PlanitDialog', PlanitDialog)
app.component('PlanitIcon', PlanitIcon)
app.component('PlanitButton', PlanitButton)
app.component('PlanitSwitch', PlanitSwitch)
app.component('PlanitChip', PlanitChip)
app.component('planitTextField', planitTextField)

if (!window.disable_prima_vuejs_app) {
  app.mount('#app')
}

// app.config.performance = true

/* if (true) {
  app.config.performance = true
} */
  // configureCompat({
  //   // default everything to Vue 2 behavior
  //   MODE: 1,
  // })
// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}

export  { app }
